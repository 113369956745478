import { DialogService }            from 'aurelia-dialog';
import { bindable, inject }         from 'aurelia-framework';
import { I18N }                     from 'aurelia-i18n';
import { BaseOrderedListViewModel } from 'base-ordered-list-view-model';
import { FilterFormSchema }         from 'modules/map/management/suppliers/filter-form-schema';
import { ReclassifySuppliers }      from 'modules/map/management/suppliers/reclassify';
import { InfoDialog }               from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }             from 'resources/services/app-container';
import { Downloader }               from 'resources/services/downloader';
import { SuppliersRepository }      from './services/repository';

@inject(AppContainer, SuppliersRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListSuppliers extends BaseOrderedListViewModel {

    listingId = 'suppliers-listing';

    @bindable headerTitle    = 'listing.map.management.suppliers';
    @bindable newRecordRoute = 'map.management.suppliers.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.management.suppliers.index') && super.can([
            'map.management.suppliers.manage',
            'map.management.suppliers.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.suppliers.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.suppliers.manage', 'map.management.suppliers.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('map.management.suppliers.edit', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.suppliers.manage', 'map.management.suppliers.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['map.management.suppliers.manage', 'map.management.suppliers.delete']),
            },
            options:            [
                {
                    label:   'form.button.reclassify-suppliers',
                    icon:    'icon-truck',
                    action:  () => this.ensureSelectedRows(() => this.viewReclassify(this.datatable.instance.selectedRows)),
                    visible: () => this.appContainer.authenticatedUser.can(['map.mat-app-proposals.manage', 'map.mat-app-proposals.edit']),
                },
            ],
            selectable:         true,
            destroySelected:    true,
            actionsContextMenu: true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'name',
                    name:  'supplier_translations.name',
                    title: 'form.field.name',
                },
                {
                    data:       'status',
                    name:       'boolean_statuses.name',
                    searchable: false,
                    title:      'form.field.status',
                    type:       'label',
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'suppliers.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Opens the application setting modal
     *
     */
    viewReclassify(selectedRows) {
        this.dialogService
            .open({
                viewModel: ReclassifySuppliers,
                model:     { selected_ids: selectedRows },
            })
            .whenClosed((response) => this.datatable.instance.reload());
    }

}
