import { inject }                    from 'aurelia-framework';
import { SuppliersRepository }       from 'modules/map/management/suppliers/services/repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { ReclassifySupplier }        from 'modules/map/models/reclassify-supplier';

@inject(SuppliersRepository, MatAppProposalsRepository)
export class FormSchema {

    /**
     * Constructor
     *
     * @param suppliersRepository
     * @param mapRepository
     */
    constructor(suppliersRepository, mapRepository) {
        this.suppliersRepository = suppliersRepository;
        this.mapRepository       = mapRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ReclassifySupplier}
     */
    model() {
        return new ReclassifySupplier();
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {

        this.other_supplier = {
            type:         'multiselect-native',
            key:          'other_supplier',
            label:        'form.field.other-supplier',
            remoteSource: () => this.mapRepository.otherSuppliers(),
            size:         12,
            observers:    [
                (newValue) => {
                    const hasItems = this.other_supplier.instance.selectedItems().length > 0;
                    this.mat_app_proposals.instance.disable(!hasItems);
                    this.mat_app_proposals.instance.required(hasItems);

                    if (hasItems) {
                        this.mat_app_proposals.instance.fetchData();
                    }
                },
            ],
            hidden:       viewModel.selected_ids !== null,
        };

        this.mat_app_proposals = {
            type:         'multiselect-native',
            key:          'mat_app_proposals',
            label:        'form.field.mat-app-proposals',
            remoteSource: () => viewModel.model.other_supplier
                ? this.mapRepository.allByOtherSupplier({ other_suppliers: viewModel.model.other_supplier })
                : Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            size:         12,
            hidden:       viewModel.selected_ids !== null,
        };

        this.supplier_id = {
            type:         'select2',
            key:          'supplier_id',
            label:        'form.field.supplier',
            remoteSource: () => this.suppliersRepository.active(),
            size:         12,
        };

        return [
            [this.other_supplier],
            [this.mat_app_proposals],
            [this.supplier_id],
        ];
    }
}
