import { DialogController, DialogService } from 'aurelia-dialog';
import { bindable, inject }                from 'aurelia-framework';
import { BaseFormViewModel }               from 'base-form-view-model';
import { FormSchema }                      from 'modules/map/management/manufacturers/reclassify/form-schema';
import { MatAppProposalsRepository }       from 'modules/map/mat-app-proposals/services/repository';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, MatAppProposalsRepository, FormSchema, DialogController, DialogService)
export class ReclassifyManufacturers extends BaseFormViewModel {

    @bindable settings = {
        id:      'reclassify-manufacturers-modal',
        title:   'form.title.reclassify-manufacturers',
        buttons: true,
        size:    'modal-md',
    };

    type = 'manufacturer';

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param dialogController
     * @param dialogService
     */
    constructor(appContainer, repository, formSchema, dialogController, dialogService) {
        super(appContainer);

        this.repository       = repository;
        this.dialogController = dialogController;
        this.dialogService    = dialogService;
        this.formSchema       = formSchema;
        this.self             = this;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('map.management.manufacturers.index') && super.can([
            'map.management.manufacturers.manage',
            'map.management.manufacturers.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        this.selected_ids = params.selected_ids;

        this.schema       = this.formSchema.schema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        if (this.selected_ids) {
            this.model.other_manufacturer = ['.'];
            this.model.mat_app_proposals  = this.selected_ids;
        }
    }

    /**
     * Submits form
     */
    submit() {
        this.alert = null;

        return this.repository
            .reclassifyMAP(this.type, this.model)
            .then((response) => {
                this.alert = this.alertMessage(response.status, response.message, response.errors);

                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.initialModel.assign(this.model);

                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }
}
